import React from 'react';
import './Footer.css'

function Footer() {
  return (
    <div>
        <footer>
            <p>Created by: Marc Dennis - <strong>marc.dennis@auggiedoggy.net</strong> - <em>2024</em></p>
        </footer>
    </div>
  );
}

export default Footer;
