import React, { useEffect, useState } from 'react';
import "./SpotifyAPISongRecs.css";

const SpotifyAPISongRecs = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [expiresIn, setExpiresIn] = useState(null);

  useEffect(() => {
    const clientID = '9e9c0569f9424efd90924d1a9cc3e188';
    const clientSecret = '92a6e11d19d543d3ac9115355145b4e0';

    const getAccessToken = async () => {
      try {
        const response = await fetch('https://accounts.spotify.com/api/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic ' + btoa(clientID + ':' + clientSecret)
          },
          body: new URLSearchParams({
            'grant_type': 'client_credentials'
          })
        });

        const data = await response.json();
        setAccessToken(data.access_token);
        setExpiresIn(data.expires_in);
      } catch (err) {
        console.error(err);
      }
    };

    getAccessToken();
  }, []);

  const getArtistData = async (artistId) => {
    try {
      const response = await fetch(`https://api.spotify.com/v1/artists/${artistId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      const data = await response.json();
      console.log(data);
    
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div className="Spotify-Container">
     
    </div>
  );
};

export default SpotifyAPISongRecs;
