import React from 'react';
import Carousel from '../Carousel/Carousel';  // Correct the path to the Carousel component
import NavBar from '../NavBar/NavBar';  // Correct the path to the NavBar component
import Footer from '../Footer/Footer';  // Correct the path to the Footer component
import Tiny from "../../img/Tiny.jpeg";
import Lyla from "../../img/Lyla.jpeg";
import Ellie from "../../img/Ellie.jpeg";
import Aug from "../../img/Aug2.jpg";
import TK from "../../img/TK.jpg";
import Izzy from "../../img/Izzy.jpeg";
import Arya from "../../img/Arya.jpeg";
import Thor from "../../img/Thor.jpg";
import './Crew.css';

function Crew() {

  const images = [
    {
      url: Tiny,  // Correct the image URL import
      label: 'Tiny',
      description: 'Tiny is our eldest, he recently turned 15 years old! The sweetest of the bunch, he has been with Kayla since birth, never leaving her side!'
    },
    {
      url: Lyla, 
      label: 'Lyla',
      description: 'Lyla is our 12 year old Chihuahua-Terrier, she loves heated blankets and to bite toes.'
    },
    {
      url: Ellie,  
      label: 'Eleanor',
      description: 'Eleanor is a Blue-heeler mix. Her favorite past-time is fetch, she can play all day!'
    },
    {
      url: Aug,  
      label: 'Augustus',
      description: 'The youngest, Augustus, he loves to lounge and cuddles!'
    },
    {
      url: Izzy,  
      label: 'Isabel',
      description: 'Goes by Izzy, she is our most senior cat and a mother figure to everyone. Loves to groom ears!'
    },
    {
      url: TK,  
      label: 'Trash Kat',
      description: 'Adopted as a kitten, Trash Kat is our house void. She is the most demanding of attention, at times opening doors to get your attention! She is also the biologoical mother to Arya and Thor.'
    },
    {
      url: Arya, 
      label: 'Arya',
      description: 'Arya is one of Trash Kats kittens kept. She is the most independent and outgoing, her favorite activity is exercising on her cat wheel.'
    },
    {
      url: Thor,  
      label: 'Thor',
      description: 'Thor is Aryas twin brother, unlike Arya he is a lot more timid. Preferring the safety of a elevated cat-tree, when he does come down he loves attention!'
    }
  ];

  return (
    <div className="MainDiv">
      <div className="Heading">
        <h1>Meet Everyone!</h1>
      </div>

      <NavBar />

      <Carousel images={images} />

      <Footer />

    </div>
  );
}

export default Crew;
