import React from "react";
import "./BlogPost.css";

const BlogPost = ({object}) => {
   
   return (
    <div>
      <div className="BlogPostContent">
        <img src={object.url} alt={object.description}/>

        <p>{object.comment}</p>

        <div id="Post-Date">
          <p><em><strong>Posted:</strong></em><br/>April 8, 2024<br/><em>3:10PM</em></p>
        </div>
        
      </div>
      
    </div>
  );
};

export default BlogPost;