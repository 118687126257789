import React from 'react';
import NavBar from '../NavBar/NavBar';
import ContactForm from '../ContactForm/ContactForm';
import Footer from '../Footer/Footer';
import "./About.css";

function About() {
  return (
    <div className="MainDiv">
      <div className="Heading">
        <h1>About AuggieDoggy.net</h1>
      </div>

      <NavBar />

      <div className="Content">
        <div>
          <h2 className='about-h2'>This site was created with love and dedication to my loving family. I love you!</h2>
        </div>

        <ContactForm />
      </div>

      <Footer />
    </div>
  );
}

export default About;
