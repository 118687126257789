import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './Carousel.css';

const handleDragStart = (e) => e.preventDefault();

function Carousel ({ images }) { 
  const items = images.map((imageDetail) => (
    <div className="CarouselDiv">
      <h2>{imageDetail.label}</h2>
      <img className="carousel-img" src={imageDetail.url} alt={imageDetail.label} onDragStart={handleDragStart} role="presentation"/>
      <p>{imageDetail.description}</p>
    </div>
  ));

  return <AliceCarousel 
          mouseTracking
          keyboardNavigation 
          animationType='fadeout' 
          infinite 
          autoPlay 
          autoPlayInterval={15000} 
          autoPlayStrategy='none'  
          items={items}  />;
}

export default Carousel;