import React from 'react';
import './Home.css'
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';

function HomePage() {
  return (
    <div class="MainDiv">
        <body>
            <div className="Heading">
                <h1>Welcome to Augustus' Website!</h1>
            </div>

            <div className="Nav">
                <NavBar />
            </div>

            <div className="Content">

                <img src="../img/aug.jpeg" alt="Augustus Wolfram Dennis" height="500" width="850"/>

                <p>Augustus is a piebald dachshund, a small bundle of joy with a coat as unique as his personality! </p>
            </div>
        </body>

        <Footer />
        
    </div>
  );
}

export default HomePage;
