import React from 'react';
import './AryaTunes.css';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import BlogPost from '../BlogPost/BlogPost';
import SpotifyAPISongRecs from '../SpotifyAPISongRecs/SpotifyAPISongRecs';

function AryaTunes() {
    const post1 = {
        url: "../../img/Arya.jpeg",
        description: "Arya-Profile-Pic",
        comment: "I am Arya Nightingale, known amongst my family as the one with the best music taste (Besides mom 🤪). This is the space where I will show you my current recommendations. Always stay tuned for more!",
        date: "Today"
    };

  return (
    <div>
      <div className="Heading">
        <h1>Arya's Tunes</h1>
      </div>

      <NavBar />

      <div className="AryaTuneBlogPostDiv">
        <BlogPost object={post1} />
      </div>
      
      <SpotifyAPISongRecs />
      
      <Footer />

    </div>
  );
}

export default AryaTunes;
