import React from 'react';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import './App.css';
import HomePage from '../Home/Home';
import About from '../About/About';
import Crew from '../Crew/Crew';
import AryaTunes from '../AryaTunes/AryaTunes';

function App() {
  return (
    <div>
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/Crew" element={<Crew />} />
                <Route path="/AryaTunes" element={<AryaTunes />} />
                <Route path="/About" element={<About/>} />
            </Routes>
        </Router>
    </div>
  );
}

export default App;
