import React from 'react';
import {Link} from 'react-router-dom';
import './NavBar.css';

function NavBar() {


  return (
    <div className="MainDiv">
      <nav>
        <Link to="/">Home</Link>
        <Link to="/Crew">The Crew</Link>
        <Link to="/AryaTunes">Arya's Tunes</Link>
        <Link to="/About">About</Link>
      </nav>
    </div>
  );
}

export default NavBar;
