import { useState } from 'react';
import { send } from 'emailjs-com';
import "./ContactForm.css";

const ContactForm = () => {
   const [toSend, setToSend] = useState({
    first_name: '',
    last_name: '',
    subject: '',
    message: '',
    email: ''
   });

   const handleChange = (e) => {
      setToSend({...toSend, [e.target.name] : e.target.value})
   }

   const onSubmit = (e) => {
    e.preventDefault();

    send(
      'service_keo3s7k',
      'template_sr5fyfh',
      toSend,
      'S-cMR3LqGx2u9v-gt'
    ).then((response) => {
      console.log("Success!" , response.status , response.text);
      setToSend({
        first_name: '',
        last_name: '',
        subject: '',
        message: '',
        email: ''
      });
    }).catch((err) => {
      console.log("FAILED...", err)
    }).then(() => {
      
    });
    
   }
   return (
    <div>
      <form id="ContactForm" onSubmit={onSubmit}>
      
        <label> Send Us A Message!</label>
    
        
        <div className="form-input">
          <input
            type='Text'
            name='first_name'
            placeholder='First Name'
            value={toSend.first_name}
            onChange={handleChange}
          />
        </div>
        <div className="form-input">
          <input
            type='Text'
            name='last_name'
            placeholder='Last Name'
            value={toSend.last_name}
            onChange={handleChange}
          />
        </div>
        <div className="form-input">
          <input
            type='Text'
            name='subject'
            placeholder='Subject'
            value={toSend.subject}
            onChange={handleChange}
          />
        </div>
        <div className="form-input">
        <textarea
          name="message"
          placeholder="Message"
          value={toSend.message}
          onChange={handleChange}
          rows="6" /* Adjust rows for desired height */
        />
        </div>
        <div className="form-input">
          <input
            type='Text'
            name='email'
            placeholder='Email'
            value={toSend.email}
            onChange={handleChange}
          />
        </div>
        
        <input type='submit' value='Submit' />
      </form>
    </div>
  );
};

export default ContactForm;